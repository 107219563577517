.table td, .table th{
  border: none;
}
.containerFormat{
  background-color: #f7f7f7;
  height: 100%;
}
.pageLayout{
  min-height:100%;
}
html ,body{
  height: 100%;
  margin: 0;
}
.headerFormat{
  color: black;
}

.middleRow{
  padding-left:30px;
}
.middleRow2{
  padding-left:5%;
  padding-right:5%;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 2px solid #1b709e;

}
.borderFormat{
  border-left: 3px solid black;
}



