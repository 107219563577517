$font-path: '~@tuxedo/bootstrap-theme/fonts';
$image-path: '~@tuxedo/bootstrap-theme/images';
$icon-path: '~@tuxedo/bootstrap-theme/images/icons';
@import '~@tuxedo/bootstrap-theme/scss/tuxedo-components';
@import '~@tuxedo/bootstrap-theme/scss/tuxedo-animations';
@import '~@tuxedo/bootstrap-theme/scss/base/palette';
@import '~@tuxedo/bootstrap-theme/scss/tuxedo-icons';

.content {
  //display: flex;
  //flex-direction: column;
  margin-left: 3.125rem;
  min-height: 100vh;
  transition: all 0.3s ease;

  &.navigation-expanded {
    @media screen and (min-width: 768px) {
      margin-left: 15rem;
    }

    @media screen and (min-width: 1200px) {
      margin-left: 18.75rem;
    }

  }
}

//body::-webkit-scrollbar{
// @media screen and (min-width: 450px) {
//overflow-x:hidden;
//}
//}

.app-container{
  overflow-x:hidden;
  overflow-y:auto;
}

a {
  text-decoration: underline;
}

.emphasized_link{
  text-decoration: none;
}
.content-body{
  flex:1;
  max-width:1600px;
  margin: 0 auto;
}
.center-body{
  padding-right:15px;
  padding-left:15px;
  margin-right:auto;
  margin-left:auto;
}
//added new changes
.main{
  margin-left: 45px;
  transition: all 0.3s ease;
}

.content_body{
  min-height: calc(100vh - 160px);
  max-width: 1600px;
  margin: 0 auto;
}

.global_navigation.add_icons .level0 a, .global_navigation.add_icons .level0 button {
  padding-left: 48px !important;
}

@media screen and (min-width: 768px){
  .main.menuExpanded{
    margin-left: 240px;
  }
}

@media screen and (min-width: 1200px){
  .main.menuExpanded{
    margin-left: 300px;
  }
}

@media (max-width: 320px) {
  html {
    font-size:0.8em;
  }
}

.border-top-blue {
  border-top: 3px solid #1b709e!important;
  height: 100%;
}
.bg-white{
  background: white;
  height: 100%;

}

.icon.chevron-o-right.aquamarine{
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg  viewBox%3D%222 2 20 20%22  xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath d%3D%22M4.006 11.915a7.993 7.993 0 0 0 7.92 8.08 7.993 7.993 0 0 0 8.08-7.922 7.993 7.993 0 0 0-7.921-8.078 7.993 7.993 0 0 0-8.079 7.92zm1.454.015a6.539 6.539 0 0 1 6.61-6.48 6.539 6.539 0 0 1 6.48 6.61 6.539 6.539 0 0 1-6.609 6.48 6.539 6.539 0 0 1-6.48-6.61zm4.718 2.57c-.29.363-.267.923.051 1.252.318.33.81.302 1.101-.06l2.472-3.09a.979.979 0 0 0 .012-1.179l-2.41-3.138c-.284-.368-.776-.405-1.1-.083a.972.972 0 0 0-.076 1.25l1.954 2.545z%22 fill%3D%22%231B709E%22%2F%3E%3C%2Fsvg%3E')
}

.icon.external-link.coral{
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg  viewBox%3D%222 2 20 20%22  xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath d%3D%22M18 7.414l-6.848 6.849a1 1 0 1 1-1.415-1.415L16.586 6h-2.253a1 1 0 0 1 0-2H19a1 1 0 0 1 1 1v4.667a1 1 0 0 1-2 0zm-2.333 5.364a1 1 0 1 1 2 0v4.666A2.556 2.556 0 0 1 15.11 20H6.556A2.556 2.556 0 0 1 4 17.444V8.89a2.556 2.556 0 0 1 2.556-2.556h4.666a1 1 0 1 1 0 2H6.556A.556.556 0 0 0 6 8.89v8.555c0 .307.249.556.556.556h8.555a.556.556 0 0 0 .556-.556z%22 fill%3D%22%23CE3E27%22%2F%3E%3C%2Fsvg%3E')
}
.icon.document-acrobat-o.aquamarine{
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg  viewBox%3D%222 2 20 20%22  xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath d%3D%22M12.778 4c.206 0 .404.077.55.213l5.444 5.09a.704.704 0 0 1 .228.515v8C19 19.023 17.955 20 16.667 20H7.333C6.045 20 5 19.023 5 17.818V6.182C5 4.977 6.045 4 7.333 4zM7.333 5.455c-.43 0-.777.325-.777.727v11.636c0 .402.348.727.777.727h9.334c.43 0 .777-.325.777-.727V10.12l-4.988-4.664zm6.223 3.636h4.666c.43 0 .778.326.778.727 0 .402-.348.727-.778.727h-5.444c-.43 0-.778-.325-.778-.727v-5.09c0-.402.348-.728.778-.728s.778.326.778.727zm1.814 7.278c-.11.11-.284.164-.52.164-.24 0-.518-.036-.839-.11a4.61 4.61 0 0 1-.946-.329c-.988.11-1.775.257-2.36.44l-.083.055c-.366.621-.69 1.074-.973 1.358-.285.284-.545.425-.783.425l-.247-.054-.274-.193-.028-.027a.543.543 0 0 1-.027-.302c.018-.165.119-.357.302-.577.183-.22.447-.448.796-.686l.521-.274.55-1.043a9.151 9.151 0 0 0 .63-1.73 5.537 5.537 0 0 1-.22-.932c-.037-.274 0-.603.11-.988a.553.553 0 0 1 .165-.302.419.419 0 0 1 .302-.137h.137a.55.55 0 0 1 .357.137c.182.202.255.503.22.906-.038.403-.11.805-.22 1.207l-.027.083c.255.677.566 1.171.932 1.482l.522.356.768-.054c.714 0 1.152.137 1.317.411a.44.44 0 0 1 .083.343.728.728 0 0 1-.165.371zm-.247-.412c-.018-.037-.11-.082-.274-.137-.165-.055-.449-.082-.85-.082-.038 0-.075.018-.11.055.438.201.804.301 1.097.301h.137c.036 0 .055-.027.055-.082zm-3.595-3.21l.028-.029.109-.576c.055-.292.037-.503-.055-.63l-.082-.028-.055.082c-.092.257-.073.65.055 1.18zm.055 1.371c-.146.44-.348.915-.604 1.427l-.301.604.081-.028c.769-.274 1.419-.456 1.95-.548l-.248-.192c-.348-.292-.64-.714-.878-1.263zm-1.839 2.69l-.274.164a5.056 5.056 0 0 0-.494.563c-.128.174-.201.315-.22.425.275-.073.605-.456.988-1.152z%22 fill%3D%22%231B709E%22%2F%3E%3C%2Fsvg%3E')
}
.icon.university.charcoal{
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg width%3D%2216%22 height%3D%2214%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Cdefs%3E%3Cpath d%3D%22M16 3v.5c0 .14-.12.25-.27.25h-.8v.38c0 .2-.18.37-.4.37H1.47c-.22 0-.4-.17-.4-.37v-.38h-.8C.12 3.75 0 3.64 0 3.5V3c0-.1.07-.2.16-.23L7.9.02a.28.28 0 0 1 .2 0l7.74 2.75c.1.04.16.13.16.23zm-.8 9.5H.8c-.44 0-.8.34-.8.75v.5c0 .14.12.25.27.25h15.46c.15 0 .27-.11.27-.25v-.5c0-.41-.36-.75-.8-.75zM2.13 5v6h-.66c-.22 0-.4.17-.4.38V12h13.86v-.62a.39.39 0 0 0-.4-.38h-.66V5h-2.14v6h-1.06V5H8.53v6H7.47V5H5.33v6H4.27V5H2.13z%22 id%3D%22a%22%2F%3E%3C%2Fdefs%3E%3Cuse xlink%3Ahref%3D%22%23a%22 fill%3D%22%23333333%22 fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E')
}
.icon.medicalPlan {
  background-image :url('assets/images/visit/medicalplan.svg');
  width: 39px !important;
  height: 34px !important;
}
.icon.show.aquamarine{
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg  viewBox%3D%222 2 20 20%22  xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath d%3D%22M12 6c4.737 0 9 5.002 9 6.132C21 13.212 16.737 18 12 18c-4.736 0-9-4.788-9-5.868C3 11.002 7.264 6 12 6zm3.588 9.305c1.885-.993 3.296-2.49 3.846-3.186-.535-.714-1.903-2.255-3.734-3.307.503.9.802 1.988.802 3.162a6.421 6.421 0 0 1-.914 3.33zM8.301 8.812c-1.831 1.052-3.2 2.593-3.734 3.307.55.696 1.961 2.193 3.846 3.186a6.413 6.413 0 0 1-.914-3.33 6.49 6.49 0 0 1 .802-3.163zM12 15.821c1.714 0 3.107-1.724 3.107-3.847 0-2.12-1.393-3.846-3.107-3.846-1.713 0-3.106 1.725-3.106 3.846 0 2.123 1.393 3.847 3.106 3.847zm1.132-3.962c.322 0 .603-.184.8-.461.038.186.064.377.064.577 0 1.329-.87 2.407-1.944 2.407-1.073 0-1.943-1.078-1.943-2.407 0-1.33.87-2.407 1.943-2.407.103 0 .202.019.3.038-.183.24-.3.56-.3.916 0 .739.483 1.337 1.08 1.337z%22 fill%3D%22%231B709E%22 fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E')
}

.icon.BeneficiaryDesignationIcon {
  /*background-image :url('assets/images/visit/BeneficiaryDesignationIcon.png');*/
  background-image :url('assets/images/visit/BeneficiaryDesignation.png');

}

.icon.BenefitsWebCenterIcon {
  /*background-image :url('assets/images/visit/BenefitsWebCenterIcon.png');*/
  background-image :url('assets/images/visit/BenefitsWebCenter.png');
  width: 27px !important;
  height: 30px !important;
}

.icon.MRAIcon2019 {
  /*background-image :url('assets/images/visit/MRAIcon2019.png');*/
  background-image :url('assets/images/visit/2019MraActivities.png');
  width: 27px !important;
  height: 30px !important;
}

.icon.TrackyourWellnessScreeningIcon {
  /*background-image :url('assets/images/visit/TrackyourWellnessScreeningIcon.png');*/
  background-image :url('assets/images/visit/TrackWellnessScreening.png');
  width: 27px !important;
  height: 30px !important;
}

.icon.AE19_icons_noCircle_Assessment {
  background-image :url('assets/images/visit/AE19_icons_noCircle_Assessment.png');
  width: 27px !important;
  height: 30px !important;
}

.icon.AE19_icons_noCircle_Screening {
  background-image :url('assets/images/visit/AE19_icons_noCircle_Screening.png');
  width: 27px !important;
  height: 30px !important;
}

.icon.CVS {
  background-image :url('assets/images/visit/CVS.svg');
  width: 39px !important;
  height: 30px !important;
}
.icon.tooth {
  background-image :url('assets/images/visit/tooth.svg');
  width: 27px !important;
  height: 30px !important;
}
dl.inline dt {
  text-align: left !important;
  padding-bottom: 40px;
}
.icon_size_md{
  width: 30px !important;
  height: 30px !important;
}
.icon_size{
  width: 38px !important;
  height: 32px !important;
}

dl dd {
  font-weight: 400;
}
.tbl_des{
  margin-bottom: 0px !important;
}
.tbl_des tr td{
  color:#1b709e

}

table {
  border-collapse: collapse;
  border-style: hidden;
}
.table td{
  padding: .75rem;
  vertical-align: top;
  border: 2px solid #dee2e6;
}

.card-shadow{
  height:100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 4px;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 2px solid #20c997;
}
.mt {
  margin-top: 40px;
}
.card-body_cus{
  padding-top: 15px;
  padding-bottom: 0px ! important;
}


.border-0{
  border:0;
}
.cust-img{
  margin-top:20px;
  height:60px;
}

.PageBackButton{
  padding: 15px;
}
.PageBackButton p {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 0;
  line-height: 22px;
}
.PageBackButton span{
  display: inline-block;
  height: 22px;
  width: 22px;
  background-color: #1b709e;
  position: relative;
  vertical-align: top;
}
.PageBackButton span .icon{
  height: 14px;
  width: 14px;
  position: absolute;
  left: 45%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.btn_orange {
  border: 1px solid #ce3e27;
  background-color: #fff;
  padding-left: 40px;
  position: relative;
}
.icon.chevron-o-right.coral{
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg  viewBox%3D%222 2 20 20%22  xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath d%3D%22M4.006 11.915a7.993 7.993 0 0 0 7.92 8.08 7.993 7.993 0 0 0 8.08-7.922 7.993 7.993 0 0 0-7.921-8.078 7.993 7.993 0 0 0-8.079 7.92zm1.454.015a6.539 6.539 0 0 1 6.61-6.48 6.539 6.539 0 0 1 6.48 6.61 6.539 6.539 0 0 1-6.609 6.48 6.539 6.539 0 0 1-6.48-6.61zm4.718 2.57c-.29.363-.267.923.051 1.252.318.33.81.302 1.101-.06l2.472-3.09a.979.979 0 0 0 .012-1.179l-2.41-3.138c-.284-.368-.776-.405-1.1-.083a.972.972 0 0 0-.076 1.25l1.954 2.545z%22 fill%3D%22%23CE3E27%22%2F%3E%3C%2Fsvg%3E')
}
.icon.download.aquamarine{
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg  viewBox%3D%222 2 20 20%22  xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath d%3D%22M11.2 12.469V4.8a.8.8 0 1 1 1.6 0v7.669l2.634-2.635a.8.8 0 1 1 1.132 1.132l-4 4a.8.8 0 0 1-1.132 0l-4-4a.8.8 0 1 1 1.132-1.132zm7.2 1.931a.8.8 0 1 1 1.6 0v3.2a2.4 2.4 0 0 1-2.4 2.4H6.4A2.4 2.4 0 0 1 4 17.6v-3.2a.8.8 0 1 1 1.6 0v3.2a.8.8 0 0 0 .8.8h11.2a.8.8 0 0 0 .8-.8z%22 fill%3D%22%231B709E%22%2F%3E%3C%2Fsvg%3E')
}

.icon.download.aquamarine{
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg  viewBox%3D%222 2 20 20%22  xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath d%3D%22M11.2 12.469V4.8a.8.8 0 1 1 1.6 0v7.669l2.634-2.635a.8.8 0 1 1 1.132 1.132l-4 4a.8.8 0 0 1-1.132 0l-4-4a.8.8 0 1 1 1.132-1.132zm7.2 1.931a.8.8 0 1 1 1.6 0v3.2a2.4 2.4 0 0 1-2.4 2.4H6.4A2.4 2.4 0 0 1 4 17.6v-3.2a.8.8 0 1 1 1.6 0v3.2a.8.8 0 0 0 .8.8h11.2a.8.8 0 0 0 .8-.8z%22 fill%3D%22%231B709E%22%2F%3E%3C%2Fsvg%3E')
}
.height-100{
  height:100% !important;
}

.hr-cus{
  text-align: left;
  margin-left: 0;
  width: 50%;
}
dl.inline-cus dt {
  padding-bottom: 20px ! important;
}
.img_place{
  width:100%;
  height:auto;
  margin-top:20%
}
.tuxedo_navbar .navbar-brand img {
  width: auto;
  height: 25px;
  text-decoration: none;
}
.imgDisplay {
  padding-right: 0.4em;
}

.wd-fit{
  width:fit-content !important;
}
.txt-color{
  color:#1b709e
}
.align-center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottom-border-2{
  border-bottom:  2px solid #1b709e;
  text-decoration: none !important;
}
.bottom-border-1{
  border-bottom:  1px solid #1b709e;
  text-decoration: none !important;
}
.login-btn-align{
  width: fit-content;
  display: table !important;
  margin-left: 70px;
}

@media only screen
and (min-width: 768px) and (max-width: 1600px)  {
  a.emphasized_link{
    width:fit-content !important;
    display: table !important;
  }
}

.icon {
  display: inline-block;
  height: 1em;
  position: relative;
  vertical-align: middle;
  width: 1em;
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.global_navigation .level0 a, .global_navigation .level0 button {
  text-decoration: none !important;
}
.navbar-brand{
  text-decoration: none !important;
}

.card {
  background-color: transparent;
}

.tuxedo_navbar .navbar-brand.internal{
  flex: none;
}

.tuxedo_navbar {
  height: auto;
}

.global_navigation ul {
  height: 80%;
  overflow: auto;
}

